<template>
  <div>
    <eden-page-header :title="'Services'" :subtitle="subtitle" />
    <component
      :is="`${service}-form`"
      :action="'add'"
      @success="canLeavePage = true"
    />
    <eden-confirm-dialog
      title="Leave this page?"
      button-type="danger"
      button-text="Yes, leave"
      :show.sync="showLeaveMenu"
      @confirm="confirm"
    >
      <p>
        You haven’t saved this menu yet.Are you sure you want to leave this
        page?
      </p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
export default {
  name: "ServiceTypeAdd",
  data() {
    return {
      showLeaveMenu: false,
      canLeavePage: false,
      toPath: {},
      next: () => {},
    };
  },
  components: {
    BeautyForm: () => import("@/components/Services/Beauty/BeautyForm"),
    CleaningForm: () => import("@/components/Services/Cleaning/CleaningForm"),
    LaundryForm: () =>
      import("@/components/Services/Laundry/LaundryTypes/LaundryTypeForm"),
    MealForm: () => import("@/components/Services/Meal/MealItems/MealItemForm"),
    OneTimeOrderMenuForm: () =>
      import(
        "@/components/Services/Meal/MealOneTimeOrder/NewMenuOneTime/MenuOneTimeForm"
      ),
    MenuForm: () => import("@/components/Services/Meal/Menu/NewMenu/MenuForm"),
  },
  computed: {
    service() {
      console.log(this.$route.params.service);
      return this.$route.params.service;
    },
    subtitle() {
      return this.formatText(this.service);
    },
  },
  methods: {
    confirm() {
      this.next();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.canLeavePage && this.service === "one-time-order-menu") {
      this.showLeaveMenu = true;

      this.next = next;
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="scss"></style>
